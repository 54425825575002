import React, { useState } from 'react';
import logo from '../../../logo.png'
import Hours from './Hours';
import { Link, useHistory } from 'react-router-dom';
import useWindow from '../../common/hooks/useWindowSize';
import { AiOutlineMenu } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux'
import {toggleMenu} from '../../Menu/reducer';
import './style.css';

const Header = () => {
  const windowSize = useWindow();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const menuState = useSelector((state) => state.menu.open);
  const dispatch = useDispatch();

  const handleMenuClick = () => {
    if (menuState) {
      dispatch(toggleMenu(false));
      // history.push('/')
    } else {
      dispatch(toggleMenu(true));
      // history.push('/mnav');
    }
  }

  const handleHeaderClick = () => {
    if (menuState) {
      dispatch(toggleMenu(false));
    } 
    history.push('/')
  }

  return (
    <>
      {windowSize.width >= 750 ? (
        <section className="Header">
          <Link to="/" className="Flex-Row J-C A-C">
            <img src={logo} className={"Header-Logo"} alt="Logo" />
            <span className={"Header-Title"}>Bulk Beverage Co.</span>
          </Link>
          <Hours />
        </section>
      ) : (
        <section className="Header-Mobile" style={{backgroundColor: menuState ? 'rgb(102, 102, 102)' : undefined}}>
          {/* <Link to="/mnav" > */}
            <AiOutlineMenu size={25} color={menuState ? "white" : undefined} onClick={handleMenuClick}/>
          {/* </Link> */}
          <Link onClick={handleHeaderClick} className="Flex-Row J-C A-C">
            <span className={"Header-Title-Mobile"} style={{color: menuState ? 'white' : 'rgb(102, 102, 102)'}}>Bulk Beverage Co.</span>
          </Link>
          {/* <Hours /> */}
          {/* <Menu isOpen={isOpen}/> */}
        </section>
      )}
    </>

  )
}

export default Header;