import React from 'react';
import Slideshow from '../common/Slideshow';
import Gallery from '../Gallery/Gallery';

export default function Home() {
  return (
    <div>
      <Slideshow />
      <Gallery />
    </ div>
  )
}