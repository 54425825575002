import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './functions/Home/Home';
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './functions/common/style.css';
import Header from './functions/Home/components/Header';
import NavBar from './functions/Navbar/Navbar';
import Products from './functions/Products/Products';
import Footer from './functions/Footer/Footer';
import Menu from './functions/Menu/Menu';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux'
import menuReducer from './functions/Menu/reducer';

const store = configureStore({ reducer: {menu: menuReducer} })

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <section style={{ justifyContent: 'space-between', height: '100%', flexDirection: 'column', display: 'flex', minHeight: '100vh' }}>
          <div>
            <Header />
            <NavBar />
            <Menu />
            <Switch>
              <Route path="/product/:product" component={Products} />
              <Route path="/" component={Home} />
            </Switch>
          </div>
          <Footer />
        </section>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
