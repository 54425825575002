import React, { useEffect, useState } from 'react'
import BrandCard from '../common/BrandCard/BrandCard';
import brandData from '../Navbar/navitems.js';
import useWindow from '../common/hooks/useWindowSize';
import './style.css';

export default function Products({location}) {
  const [data, setData] = useState({})
  const {width} = useWindow()

  function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll(require.context('./logos', false, /\.(PNG|JPE?G|svg|WEBP)$/));
  const cards = images.map((image) => <BrandCard image={image.default} />)
  

  useEffect(() => {
    const itemId = location.pathname.split("/")[2]
    if (brandData) {
      const item = brandData.navItems.filter((product) => product.id == itemId)
      setData(item[0])
    }
  }, [location])
  return (
    <section className="Products-Container">
      <div className="Products-Banner">
          <p className="Product-Banner-Title" style={{fontSize: width < 750 && '3em'}} >{data.title}</p>
      </div>
      <section className="Product-Cards-Section">
        {cards}
      </section>
    </section>
  )
}