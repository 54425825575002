import twisted from '../../assets/brands/twisted_wares.JPG'
import fat from '../../assets/brands/fat_boy.PNG'
import sweeter from '../../assets/brands/sweeter_cards.WEBP'
import walker from '../../assets/brands/walker_feed.WEBP'
import rains from '../../assets/brands/when_it_rains.JPG'


export default [
  {
    image: twisted,
    title: 'Twisted Wares'
  },
  {
    image: fat,
    title: 'Fat Boy'
  },
  {
    image: sweeter,
    title: 'Sweeter Cards'
  },
  {
    image: walker,
    title: 'Walker Feeds'
  },
  {
    image: rains,
    title: 'When It Rains'
  }
]