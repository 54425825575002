import React from 'react';
import useWindow from '../common/hooks/useWindowSize';
import './style.css';

export default function Footer() {
  const { width } = useWindow();

  return (
    <section className="Footer" style={{flexWrap: `${width < 750 && 'wrap'}`}}>
      <p>3314 Secor Rd, Suite D, Toledo, Ohio 43606</p>
      <div className="Divider"></div>
      <a href="mailto:admin@bulkbeverageco.com" style={{ color: 'white' }}>admin@bulkbeverageco.com </a>
      <div className="Divider"></div>
      <p>(419) 720 - 4347</p>
    </section>
  )
}