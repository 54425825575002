import React, { useState, Fragment } from 'react';
import { useHistory} from 'react-router-dom'
import data from './navitems';
import useWindowSize from '../common/hooks/useWindowSize';
import './style.css';

export default function NavBar() {
  const [openMenu, toggleMenu] = useState(false);
  const [focused, setFocused] = useState({options: [{brands: []}]})
  const [subMenu, setSubmenu] = useState(1);
  const history = useHistory();
  const windowSize = useWindowSize();

  const handleMouseOut = () => {
    toggleMenu(false);
  }

  const handleMouseIn = (item) => {
    toggleMenu(true);
    setSubmenu(1);
    setFocused(item);
  }

  const handleBrandClick = () => {
    toggleMenu(false)
    history.push(`/product/${focused.id}`)
  }

  const brandList = focused.options.filter((subCategory) => subCategory.id == subMenu)
  const brandItems = brandList.length && brandList[0].brands.map((brand) => <span onClick={handleBrandClick} key={brand} className="Brand-Item">{brand}</span >)

  const menuHeaders = focused.options.map((subCategory) => <p onClick={() => setSubmenu(subCategory.id)} key={subCategory.title} className="Menu-Header" style={{backgroundColor: subCategory.id == subMenu ? 'rgba(102, 102, 102, 0.3)' : 'white'}}>{subCategory.title}</p >)

  const menuItems = data.navItems.map((item, index) => {
    return (
      <Fragment key={item.title}>
        <span onMouseEnter={() => handleMouseIn(item)} className="Nav-Item">{item.title}</span>
        {index !== (data.navItems.length - 1) &&
          <div className="Divider"></div>
        }
      </Fragment>
    )
  })



  return (
    <section onMouseLeave={() => handleMouseOut()}>
      {windowSize.width >= 750 && (
        <>
      <nav className="Nav-Bar">
        {menuItems}
      </nav>
      {openMenu &&
        <div className="Nav-Menu-Container">
          <div className="Nav-Menu">
            <div className="Nav-Menu-Left">
              {menuHeaders}
            </div>
            <div className="Nav-Menu-Middle">
              {brandItems}
            </div>
            <div className="Nav-Menu-Right">
              <img src={focused.thumbnail} alt="Product Thumbnail" className="Menu-Thumbnail" />
            </div>
          </div>
        </div>
      }
        </>
      )}
    </section>
  )
}