import React from 'react'
import useWindow from '../../common/hooks/useWindowSize';

export default function Hours() {
  const { width } = useWindow();
  return (
    <>
    {width >= 750 ? (
      <div className="hours">
      <span className="Mex">
        <p className="hour">Mon - Fri</p>
        <p className="hour">9am - 9pm</p>
      </span>
      <span className="Mex">
        <p className="hour">Sun</p>
        <p className="hour">11am - 6pm</p>
      </span>
    </div>
    ) : (
      <div className="hours-mobile">
      <span className="Hours-Row-Mobile">
        <p className="hour">M - F</p>
        <p className="hour">9a - 9p</p>
      </span>
      <span className="Hours-Row-Mobile">
        <p className="hour">Sun</p>
        <p className="hour">11a - 6p</p>
      </span>
    </div>
    )}
    </>


  )
}