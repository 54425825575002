import React from 'react';
import './style.css';

export default function BrandCard({
  title,
  image
}) {
  // console.log(image)
  return (
    <div className="Gallery-Item" key={title}>
    <span style={{minHeight: '175px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src={image} alt={"brand logo"} />
    </span>
    <div className="Gallery-Item-Label">{title}</div>
  </div>
  )
}