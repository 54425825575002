import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router';
import data from '../Navbar/navitems';
import { useSelector, useDispatch } from 'react-redux'
import {toggleMenu} from './reducer';
import './style.css';

const Menu = ({ isOpen }) => {
  const history = useHistory();
  const menu = useSelector((state) => state.menu.open);
  const dispatch = useDispatch();

  const handleItemClick = (id) => {
    dispatch(toggleMenu(false))
    history.push(`/product/${id}`)
  }

  const menuItems = data.navItems.map((item, index) => {
    return (
      <div key={item.title} className="Mobile-Nav-Item" onClick={() => handleItemClick(item.id)}>
        <span key={item.title} className="Mobile-Nav-Tittle">{item.title}</span>
      </div>
    )
  })
  return (
    <>
      {menu &&
        <div className="Mobile-Menu-Container">
          <p className="Mobile-Nav-Header">Collections</p>
          {menuItems}
        </div>
      }
    </>
  )
}

export default Menu;