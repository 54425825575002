import React, { useState } from 'react';
import img1 from '../../assets/img1.jpg'
import img2 from '../../assets/img2.jpg'
import img3 from '../../assets/img3.jpg'
import useInterval from '../common/hooks/useInterval';
import useWindow from '../common/hooks/useWindowSize';
import Hours from '../Home/components/Hours';

const slideImages = [
  img1,
  img2,
  img3
];

function SlideShow() {
  const [photoIndex, setPhotoIndex] = useState(0);
  const { width } = useWindow();

  useInterval(() => {
    setPhotoIndex(photoIndex + 1);
  }, 3000)

  const renderItem = () => (
    <div className='SlideShow-Image' style={{
      height: '400px',
      width: '100%',
      backgroundImage: `url(${slideImages[photoIndex % slideImages.length]})`,
    }}>
      {width < 750 &&
        <span style={{ width: '100%', height: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <span style={{width: '90%'}}>
          <h1 className="White-Text Main-Header-Mobile">Northwest Ohios</h1>
          <h1 className="White-Text Main-Header-Mobile">Newest Liquor Agency</h1>
          <p className="White-Text">Browse all of our exceptional brands and categories. We've got even more to show in-store!</p>
          </span>
        </span>
      }
    </div>
  )

  return (
    <section className="Slideshow-Container">
      {width >= 750 &&
        <div className="Left-Side">
          <span style={{ width: '70%' }}>
            <h1 className="White-Text Main-Header">Northwest Ohios</h1>
            <h1 className="White-Text Main-Header">Newest Liquor Agency</h1>
            <p className="White-Text">Browse all of our exceptional brands and categories. We've got even more to show in-store!</p>

          </span>
        </div>
      }
      {renderItem()}
      <div className="Right-Side" />
    </section>
  )
}

export default SlideShow