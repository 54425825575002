import brandImage from '../../assets/brands/walker_feed.WEBP'

const data = {
  "navItems": [
    {
      "id": 11,
      "title": "Wine",
      "options": [{
        "id": 1,
        "title": "Wine",
        "brands": [
          "Cabernet Sauvignon",
          "Zinfandel",
          "Merlot",
          "Pinot Noir",
          "Shiraz/Syrah",
          "Malbec",
          "Red Blends",
          "French Reds",
          "Italian Reds",
          "Other European and Import Reds",
          "Rose",
          "Chardonnay",
          "Sauvignon Blanc",
          "Pinot Gris",
          "Riesling",
          "Moscato",
          "French Whites",
          "Italian Whites",
          "Other European Whites",
          "Sparkling Wine",
          "Champagne",
          "Dessert Wines & Mead",
          "Regional Wines of the Midwest",
        ]
      }],
      "thumbnail": brandImage
    },

    {
      "id": 12,
      "title": "Spirits",
      "options": [{
        "id": 1,
        "title": "Spirits",
        "brands": []
      }],
      "thumbnail": brandImage
    },
    {
      "id": 13,
      "title": "Beer & Seltzer",
      "options": [{
        "id": 1,
        "title": "Beer & Seltzer",
        "brands": []
      }],
      "thumbnail": brandImage
    },
    {
      "id": 6,
      "title": "Foods",
      "options": [{
        "id": 1,
        "title": "FINE FOODS",
        "brands": [
          "McClure's",
          "Tony Packo's",
          "Pacific Pickle Works",
          "Monteleone",
          "10+ Brands of Honey",
          "Firehook",
          "Chicago Flatbread",
          "Jody's Popcorn",
          "Joe Chips",
          "Hen of the Woods",
          "10+ Brands of Jerky",
          "OMG Pretzels",
          "Eatable Popcorn",
          "Specialty Marshmallows",
          "25+ Brands of Hot Sauce",
          "Olli Charcuterie",
          "Columbus Salame"
        ],
      }, {
        "id": 2,
        "title": "CHEESE",
        "brands": [
          "Farm Country",
          "Carr Valley",
          "Schuler's",
          "Henning's",
          "Hooks",
          "Montamore",
          "Bella Vitano",
          "Jim's Cheese",
          "Yancy's Fancy",
          "Cabot",
          "And more!"
        ],
      }],
      "thumbnail": brandImage
    },
    {
      "id": 2,
      "title": "Garnishes and Mixers",
      "options": [
        {
          "id": 1,
          "title": "LUXURY CHERRIES",
          "brands": [
            "Luxardo",
            "Elijah Craig",
            "Traverse City",
            "Woodford Reserve",
            "Filthy",
            "Tiller Farms",
            "Egbert's",
            "Toschi"
          ],
        },
        {
          "id": 2,
          "title": "GOURMET OLIVES",
          "brands": [
            "Gil's Gourmet",
            "Collins",
            "Filthy",
            "Santa Barbara",
            "Penna",
            "Alreef"
          ],
        },
        {
          "id": 3,
          "title": "CRAFT BLOODY MARY",
          "brands": [
            "Vivio's",
            "Bentley's",
            "Toma",
            "Uncle Dougie's",
            "Hella",
            "Walker's ",
            "McClure's"
          ],
        },
        {
          "id": 4,
          "title": "MIXERS",
          "brands": [
            "Centr CBD Water",
            "Vybes CBD ",
            "POM",
            "Everfresh",
            "Simply",
            "Joe Tea",
            "La Croix",
            "Monster",
            "Red Bull",
            "Calypso",
            "Fever Tree",
            "Hal's New York",
            "Nomi",
            "Voss",
            "San Pellegrino",
            "White Rock",
            "Ocean Spray"
          ],
        }
      ],
      "thumbnail": brandImage
    },
    {
      "id": 1,
      "title": "Novelties",
      "options": [
        {
          "id": 1,
          "title": "CRAFT SODA",
          "brands": [
            "Frostie",
            "Jarritos",
            "Faygo",
            "Coca Cola",
            "Pepsi",
            "7-Up",
            "Money Bagg",
            "Sioux City",
            "Virgils",
            "Sinalco",
            "Abita",
            "Crush",
            "Hank's",
            "Cheerwine",
            "Boylan Bottling Co.",
            "Jones Soda",
            "Sprecher",
            "Dad's Soda",
            "Melba's Fixings",
            "Lester's Fixings"
          ],
        },
        {
          "id": 2,
          "title": "SPECIALTY CANDY",
          "brands": [
            "Sour Strips",
            "Trolli",
            "Nostalgic",
            "Dave's Sweet Tooth",
            "Haribo",
            "Candy Club",
            "Zingermnan's",
            "Sugarfina",
            "Sugarpova",
            "California Gummy Bears",
            "Godiva",
            "Dick Taylor Chocolate",
            "Liquer Filled Chocolates",
            "Nassau Chocolates"
          ],
        }
      ],
      "thumbnail": brandImage
    },
    {
      "id": 9,
      "title": "Accessories",
      "options": [{
        "id": 1,
        "title": "ACCESSORIES",
        "brands": [
          "Party Supplies",
          "Custom Napkins",
          "Barware/Drinkware",
          "Viski ",
          "Joy Jolt",
          "Bottle Gift Bags",
          "Drinking Games",
          "And More!"
        ],
      }],
      "thumbnail": brandImage
    },
    // {
    //   "id": 2,
    //   "title": "SPECIALTY CANDY",
    //   "brands": [
    //     "Sour Strips",
    //     "Trolli",
    //     "Nostalgic",
    //     "Dave's Sweet Tooth",
    //     "Haribo",
    //     "Candy Club",
    //     "Zingermnan's",
    //     "Sugarfina",
    //     "Sugarpova",
    //     "California Gummy Bears",
    //     "Godiva",
    //     "Dick Taylor Chocolate",
    //     "Liquer Filled Chocolates",
    //     "Nassau Chocolates"
    //   ],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 3,
    //   "title": "LUXURY CHERRIES",
    //   "brands": [
    //     "Luxardo",
    //     "Elijah Craig",
    //     "Traverse City",
    //     "Woodford Reserve",
    //     "Filthy",
    //     "Tiller Farms",
    //     "Egbert's",
    //     "Toschi"
    //   ],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 4,
    //   "title": "GOURMET OLIVES",
    //   "brands": [
    //     "Gil's Gourmet",
    //     "Collins",
    //     "Filthy",
    //     "Santa Barbara",
    //     "Penna",
    //     "Alreef"
    //   ],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 5,
    //   "title": "CRAFT BLOODY MARY",
    //   "brands": [
    //     "Vivio's",
    //     "Bentley's",
    //     "Toma",
    //     "Uncle Dougie's",
    //     "Hella",
    //     "Walker's ",
    //     "McClure's"
    //   ],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 6,
    //   "title": "FOODS",
    //   "options": [{
    // "id": 1,
    //     "title": "FINE FOODS",
    //     "brands": [
    //       "McClure's",
    //       "Tony Packo's",
    //       "Pacific Pickle Works",
    //       "Monteleone",
    //       "10+ Brands of Honey",
    //       "Firehook",
    //       "Chicago Flatbread",
    //       "Jody's Popcorn",
    //       "Joe Chips",
    //       "Hen of the Woods",
    //       "10+ Brands of Jerky",
    //       "OMG Pretzels",
    //       "Eatable Popcorn",
    //       "Specialty Marshmallows",
    //       "25+ Brands of Hot Sauce",
    //       "Olli Charcuterie",
    //       "Columbus Salame"
    //     ],
    //   }, {
    //     "title": "CHEESE",
    //     "brands": [
    //       "Farm Country",
    //       "Carr Valley",
    //       "Schuler's",
    //       "Henning's",
    //       "Hooks",
    //       "Montamore",
    //       "Bella Vitano",
    //       "Jim's Cheese",
    //       "Yancy's Fancy",
    //       "Cabot",
    //       "And more!"
    //     ],
    //   }],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 7,
    //   "title": "LOCAL BRANDS",
    //   "brands": [
    //     "Stadium Chips & Salsa",
    //     "Rojo Chips & Salsa",
    //     "Midwest Bacon Jam",
    //     "Turkeyfoot Creek"
    //   ],
    //   "thumbnail": brandImage
    // },
    // {
    //   "id": 8,
    //   "title": "CHEESE",
    //   "brands": [
    //     "Farm Country",
    //     "Carr Valley",
    //     "Schuler's",
    //     "Henning's",
    //     "Hooks",
    //     "Montamore",
    //     "Bella Vitano",
    //     "Jim's Cheese",
    //     "Yancy's Fancy",
    //     "Cabot",
    //     "And more!"
    //   ],
    //   "thumbnail": brandImage
    // },

    // {
    //   "id": 10,
    //   "title": "NA DRINKS",
    //   "brands": [
    //     "Centr CBD Water",
    //     "Vybes CBD ",
    //     "POM",
    //     "Everfresh",
    //     "Simply",
    //     "Joe Tea",
    //     "La Croix",
    //     "Monster",
    //     "Red Bull",
    //     "Calypso",
    //     "Fever Tree",
    //     "Hal's New York",
    //     "Nomi",
    //     "Voss",
    //     "San Pellegrino",
    //     "White Rock",
    //     "Ocean Spray"
    //   ],
    //   "thumbnail": brandImage
    // },
  ]
}

export default data;