import React from 'react';
import images from './images';
import BrandCard from '../common/BrandCard/BrandCard'
import useWindow from '../common/hooks/useWindowSize';
import './style.css';

export default function Gallery() {
  const {width} = useWindow();
  const cards = images.map((brand) => {
    return (
      <BrandCard key={brand.title} title={brand.title} image={brand.image}/>
    )
  })
  return (
    <section className="Gallery-Container">
      <h2>Women Owned Brands</h2>
      <section className={width >= 750 ? "Gallery-View" : "Gallery-View-Mobile"}>
        {cards}
      </section>
    </section>
  )
}