import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    open: false
  },
  reducers: {
    toggleMenu: (state, action) => {
      state.open = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleMenu } = menuSlice.actions

export default menuSlice.reducer